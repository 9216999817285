import styled from "styled-components";

export const HomePageGrid = styled.div`
  display: grid;
  gap: 2rem;
  --columns: 2;
  grid-template-columns: 1fr 2fr;
  margin-bottom: 100px;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    --columns: 1;
  }
`;

export const ItemsGrid = styled.div`
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 550px) {
    grid-template-columns: 1fr;
  }
`;

// Single Grid Item (for home page)
export const ItemStyles = styled.div`
  text-align: center;
  position: relative;
  background: white;
  padding: 2.5rem;
  border-radius: 4px;
  box-shadow: 0 0 11px #cbd6e2;
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    transform: scale(1.02);
    box-shadow: 0 10px 50px #cbd6e2;
    z-index: 2;
  }

  img {
    height: auto;
    font-size: 0;
  }

  p {
    // top: 0;
    // transform: rotate(-2deg) translateY(-10px);
    // position: absolute;
    // width: 100%;
    // left: 0;
    // margin: 0;
    // font-size: 2rem;
    // font-size: clamp(12px, 5vw, 20px);
  }
  .mark {
    display: inline;
  }

  .title {
  }

  @keyframes shine {
    from {
      background-position: 200%;
    }
    to {
      background-position: -40px;
    }
  }
  img.loading {
    --shine: white;
    --background: var(--grey);
    background-image: linear-gradient(
      90deg,
      var(--background) 0px,
      var(--shine) 40px,
      var(--background) 80px
    );
    background-size: 500px;
    animation: shine 1s infinite linear;
  }
`;
