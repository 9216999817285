import React, { useState } from "react";
import useLatestData from "../utils/useLatestData";
import { HomePageGrid } from "../styles/Grids";
import LoadingGrid from "../components/LoadingGrid";
import ItemGrid from "../components/ItemGrid";
import useUploadForm from "../utils/useUploadForm";
import { graphql, useStaticQuery, Link } from "gatsby";
import { FiChevronLeft, FiChevronDown } from "react-icons/fi";
import styled from "styled-components";
import ProfileQuiz from "../components/ProfileQuiz";
import UploadStyles from "../styles/UploadStyles";

const StyledPageContainer = styled.div`
  .page-title {
    font-weight: bold;
  }
  .page-description {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }

  & fieldset {
    border: none;
    padding: 0;
    margin: 0;
    margin-bottom: 15px;
  }

  .message-container {
    min-height: 25px;
    text-align: center;
  }

  .error-message {
    color: var(--red);
  }
  .success-message {
    color: var(--green);
  }
`;

const inputs = [
  // each nested array is a fieldset
  [
    {
      name: "firstName",
      label: "First Name",
      type: "text",
      required: true,
      placeholder: "",
      autocomplete: "given-name",
    },
    {
      name: "lastName",
      label: "Last Name",
      type: "text",
      required: true,
      placeholder: "",
      autocomplete: "family-name",
    },
  ],
  [
    {
      name: "email",
      label: "E-mail",
      type: "text",
      required: true,
      placeholder: "",
      autocomplete: "email",
    },
    {
      name: "phone",
      label: "Phone number",
      type: "text",
      required: true,
      placeholder: "",
      autocomplete: "tel",
    },
  ],
  [
    {
      name: "dob",
      label: "Date of Birth",
      type: "text",
      required: true,
      placeholder: "",
      autocomplete: "",
    },
    {
      name: "occupation",
      label: "Occupation",
      type: "text",
      required: true,
      placeholder: "",
      autocomplete: "",
    },
  ],
  [
    {
      name: "estimateRetirementAge",
      label: "Estimated Retirement Age",
      type: "number",
      required: true,
      placeholder: "",
      autocomplete: "",
    },
    {
      name: "city",
      label: "Current City of Residence",
      type: "text",
      required: false,
      placeholder: "",
      autocomplete: "",
    },
  ],
];

const documentInputs = [
  [
    {
      name: "document",
      label: "File Upload",
      type: "file",
      required: true,
      placeholder: "",
      autocomplete: "",
      fullWidth: true,
      inputProps: {
        capture: "environment",
        accept: "image/*,.pdf",
      },
    },
  ],
];

const formCategories = {
  INSURANCE: "Insurance",
  INVESTMENTS: "Investments",
};

const formSubCategories = {
  [formCategories.INVESTMENTS]: ["Annuity", "401k", "IRA", "Other TSPs"],
  [formCategories.INSURANCE]: ["Life", "Disability", "Long-term Care"],
};

export default function UploadPage({ data }) {
  const {
    values,
    updateValue,
    submitDocument,
    error,
    loading,
    message,
    renderInputs,
  } = useUploadForm(
    {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      document: "",
      dob: "",
      formCategory: formCategories.INSURANCE,
      formSubCategory: "",
      estimateRetirementAge: "",

      // profile quiz
      distance: null,
      occupation: null,
      hasDesignations: null,
      preferredLanguage: "english",
      preferredGender: "either",
    },
    {
      requiredFields: [
        "firstName",
        "lastName",
        "email",
        "phone",
        "dob",
        "estimateRetirementAge",
        "document",
        //
        "formCategory",
        "formSubCategory",
        "distance",
        "occupation",
        "hasDesignations",
        "preferredLanguage",
        "preferredGender",
      ],
    }
  );

  return (
    <StyledPageContainer>
      <div className="text-center" style={{ margin: "30px auto 90px auto" }}>
        <h1 className="page-title">Upload Your Policy!</h1>
        <p className="page-description">
          Upload a current policy and one of our experienced advisors will
          analyze it. They will get back to you within 48 hours with a report on
          how they can help enhance your portfolio. If needed, they may also ask
          a few additional questions in order to give you the best experience
          possible.
        </p>
      </div>

      <UploadStyles onSubmit={submitDocument} id="upload-form">
        <section className="form-header">
          <h1>It's Quick & Easy!</h1>
          <p>
            To schedule time to speak with one of our sales representatives,
            complete the form, then click "next" to choose a date and time that
            works for you.
          </p>
        </section>

        <section className="form-header">
          <div className="question__text">1. Select a category*</div>
          <div className="form-categories-container">
            {Object.values(formCategories).map((cat) => (
              <div
                className={`form-category ${
                  values.formCategory === cat && "selected"
                }`}
                onClick={() =>
                  updateValue({ target: { name: "formCategory", value: cat } })
                }
              >
                {cat}
              </div>
            ))}
          </div>
        </section>

        <section className="form-header">
          <div className="question__text">2. Select a sub category*</div>
          <div className="form-categories-container">
            {!!values.formCategory &&
              !!formSubCategories[values.formCategory] &&
              formSubCategories[values.formCategory].map((cat) => (
                <div
                  className={`form-category ${
                    values.formSubCategory === cat && "selected"
                  }`}
                  onClick={() =>
                    updateValue({
                      target: { name: "formSubCategory", value: cat },
                    })
                  }
                >
                  {cat}
                </div>
              ))}
          </div>
        </section>

        <section className="form-header">
          <div className="question__text">3. Fill out a few questions</div>
        </section>

        {inputs.map((input, index) => renderInputs(input, index))}

        <section className="form-header">
          <div className="question__text">4. Upload a document</div>
        </section>

        {documentInputs.map((input, index) => renderInputs(input, index))}

        <section className="form-header">
          <div className="question__text">
            5. Get matched up with an advisor
          </div>
        </section>

        <ProfileQuiz
          //
          renderInputs={renderInputs}
          updateValue={updateValue}
        />

        <section className="form-header">
          <div className="question__text">
            6. Done! Submit your form and begin a new journey.
          </div>
        </section>

        <section className="form-footer">
          <fieldset disabled={loading}>
            <button className="submit-button" type="submit" disabled={loading}>
              {loading ? "Submitting Docs..." : "Submit"}
            </button>

            <div className="message-container">
              <div className="error-message">
                {error ? <p>Error: {error}</p> : ""}
              </div>
              <div className="success-message">
                {message ? <p>{message}</p> : ""}
              </div>
            </div>
          </fieldset>
        </section>

        <div className="form-footer__privacy">
          We're committed to your privacy. JayTech uses the information you
          provide to us to contact you about our relevant content, products, and
          services. You may unsubscribe from these communications at any time.
          For more information, check out our{" "}
          <a style={{ textDecoration: "underline" }}>Privacy Policy</a>.
        </div>
      </UploadStyles>
    </StyledPageContainer>
  );
}

export const query = graphql`
  query {
    webinars: allSanityWebinar {
      edges {
        node {
          _id
          _type
          shortDescription
          slug {
            _key
            _type
            current
          }
          price
          longDescription
          parent {
            id
          }
          name

          image {
            asset {
              url
              fluid(maxWidth: 1000, maxHeight: 750) {
                ...GatsbySanityImageFluid
              }
              metadata {
                lqip
              }
            }
          }
        }
      }
    }
  }
`;
