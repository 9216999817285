import React, { useState } from "react";
import FormStyles from "../styles/FormStyles";

const renderInputs = (
  fieldsetInputs,
  groupIndex,
  { values, updateValue, loading } = {}
) => (
  <FormStyles disabled={loading} key={`fieldset_group_${groupIndex}`}>
    <fieldset>
      {fieldsetInputs.map(
        ({
          name,
          label,
          type,
          required,
          placeholder,
          autocomplete,
          fullWidth,
          inputProps,
          options,
        }) => (
          <div
            className={`form-body__input-container ${
              fullWidth && "full-width-input"
            }`}
            key={name}
          >
            <label className="" htmlFor={name}>
              <span>{label}</span>
              <span>{required && "*"}</span>
            </label>
            <div className="form-body__input">
              {type === "select" ? (
                <select
                  id={name}
                  name={name}
                  required={required}
                  value={values[name]}
                  onChange={updateValue}
                  placeholder={placeholder}
                  style={{
                    width: "100%",
                  }}
                  {...inputProps}
                >
                  {options.map(({ name, value }) => (
                    <option key={value} value={value}>
                      {name}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  id={name}
                  type={type}
                  name={name}
                  required={required}
                  value={values[name]}
                  onChange={updateValue}
                  placeholder={placeholder}
                  autoComplete={autocomplete}
                  {...inputProps}
                />
              )}
            </div>
          </div>
        )
      )}
    </fieldset>
  </FormStyles>
);

export default function useUploadForm(defaults, { requiredFields = [] } = {}) {
  const [values, setValues] = useState(defaults);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const updateValue = (e) => {
    // check if its a number and convert
    let { name, value, type } = e.target;

    if (type === "number") {
      value = parseInt(e.target.value);
    } else if (type === "file") {
      // value = e.target.files[0];
    }

    const updates = {
      ...values,
      [name]: value,
    };

    if (name === "formCategory") {
      updates.formSubCategory = "";
    }

    setValues(updates);
  };

  // console.log({ loading, values, error, message });

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  // this is the function that is run when someone submits the form
  async function submitDocument(e) {
    e.preventDefault();
    setLoading(true);
    setError(null);

    const form = document.querySelector("#upload-form");
    console.log(form);

    const file = form.querySelector("input[type='file']").files[0];
    const doc = await toBase64(file);

    try {
      const valid = requiredFields.every((field) => !!values[field]);
      console.log({ file, requiredFields, valid, values });

      if (!valid) {
        throw new Error("Please complete all required fields!");
      }

      // gather all the data
      const body = {
        ...values,
        document: doc,
        fileType: file.name.split(".").slice(-1)[0],
      };

      // 4. Send this data the a serevrless function when they check out
      console.log({ body, doc });

      const res = await fetch(
        `${process.env.GATSBY_SERVERLESS_BASE}/submitDocument`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // "Content-Type": "multipart/form-data",
          },
          body: JSON.stringify(body),
          // body: doc,
        }
      );
      const text = JSON.parse(await res.text());

      // check if everything worked
      if (res.status >= 400 && res.status < 600) {
        setError(text.message);
      } else {
        // it worked!
        setMessage(
          "Success! Give us 48 hours to analyze your current situation!"
        );
      }
    } catch (error) {
      console.log(error);
      setError(error.text || error.message);
    }

    setLoading(false); // turn off loading
  }

  const injectParamsToRenderer = (inputs, index) =>
    renderInputs(inputs, index, {
      values,
      updateValue,
      loading,
    });

  return {
    values,
    updateValue,
    submitDocument,
    renderInputs: injectParamsToRenderer,

    error,
    loading,
    message,
  };
}
