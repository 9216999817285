import React, { useState } from "react";
import { FiChevronDown, FiChevronRight } from "react-icons/fi";
import ProfileQuizStyles from "../styles/ProfileQuizStyles";

const radioQuestions = [
  {
    field: "distance",
    question: "Having an advisor close to me",
  },
  {
    field: "occupation",
    question: "Advisor with experience with my occupation",
  },
  {
    field: "hasDesignations",
    question: "Advisor with more designations (CFP, etc)",
  },
];

const extraQuestions = [
  [
    {
      name: "preferredLanguage",
      label: "Preferred Language",
      type: "select",
      required: true,
      placeholder: "",
      autocomplete: "",
      options: [
        { name: "English", value: "english" },
        { name: "Spanish", value: "spanish" },
      ],
    },
    {
      name: "preferredGender",
      label: "Preferred Gender",
      type: "select",
      required: true,
      placeholder: "",
      autocomplete: "",
      options: [
        { name: "Either", value: "either" },
        { name: "Male", value: "male" },
        { name: "Female", value: "female" },
      ],
    },
  ],
];

export default function ProfileQuiz({ onSubmit, renderInputs, updateValue }) {
  const [showFooterNotes, setShowFooterNotes] = useState(false);

  const handleFooterClick = () => {
    setShowFooterNotes(!showFooterNotes);
  };

  return (
    <ProfileQuizStyles>
      <div className="header">Advisor Matchup</div>
      <div className="body">
        <div className="form__progress">{/* <progress /> */}</div>
        <div className="form__instructions">
          Using a scale of <b>1 = Not Important</b> to <b>5 = Very Important</b>
          , please rate the following conditions in order for us to find a
          better match for you.
        </div>
        <div className="form__answers">
          <table>
            <tr>
              <th>Condition</th>
              <th>1</th>
              <th>2</th>
              <th>3</th>
              <th>4</th>
              <th>5</th>
            </tr>

            {radioQuestions.map(({ question, field }, questionIndex) => (
              <tr key={field}>
                {/* question to display */}
                <td>{question}*</td>

                {/* answers */}
                {[...Array(5)].map((_, idx) => (
                  <td key={`${field}_${idx}`}>
                    <input
                      type="radio"
                      name={field}
                      value={idx + 1} // want this to be 1 based
                      id={`${field}_${idx}`}
                      onChange={updateValue}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </table>
        </div>

        {extraQuestions.map((q, i) => renderInputs(q, i))}

        {onSubmit && (
          <div className="form__submit">
            <button
              className="button question-submit-button"
              onClick={onSubmit}
            >
              Submit <FiChevronRight style={{ marginLeft: 12 }} />
            </button>
          </div>
        )}
        {/* <div style={{ opacity: 0.4, fontSize: 12, marginTop: 8 }}>
          this form is not doing anything currently... still working on how to submit it, but the UI is close
        </div> */}
      </div>
      <div className="footer" onClick={handleFooterClick}>
        <span>What do we do with this information?</span>
        <FiChevronDown style={{ marginLeft: 12 }} />
      </div>
      <div className={`footer__notes ${showFooterNotes ? "open" : ""}`}>
        <span>
          We will not share this information with any third parties. It will
          strictly be used to find a compatible match for you from our list of
          trusted advisors.
        </span>
      </div>
    </ProfileQuizStyles>
  );
}
