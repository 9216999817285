import React from "react";
import { ItemsGrid, ItemStyles } from "../styles/Grids";
import Img from "gatsby-image";

export default function ItemGrid({ items, handleClick }) {
  return (
    <ItemsGrid>
      {items.map((item) => (
        <ItemStyles
          key={item._id}
          onClick={() => !!handleClick && handleClick(item)}
        >
          {/* <img
            width="500"
            height="400"
            src={`${item.image.asset.url}?w=500&h=400&fit=crop`}
            alt={item.name}
            style={{
              background: `url(${item.image.asset.metadata.lqip})`,
              backgroundSize: "fit",
            }}
          /> */}

          <Img fluid={item.image.asset.fluid} />

          <p>
            <span className="title bold">{item.name}</span>
          </p>
          <p>
            <span className="">{item.shortDescription}</span>
          </p>
        </ItemStyles>
      ))}
    </ItemsGrid>
  );
}
