import styled from "styled-components";

const UploadStyles = styled.form`
  max-width: 615px;
  margin: auto auto 100px auto;
  background-color: var(--white);
  padding: 2rem 3rem;
  box-shadow: 0 0 30px rgba(50, 50, 90, 0.1);

  .form-categories-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 -3rem;
  }
  .form-category {
    flex: 1;
    border: 1px solid #0002;
    // border-top: 0;
    border-left: 0;
    padding: 10px 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 50px;
    opacity: 0.6;
    min-width: 125px;

    &:last-of-type {
      border-right: none;
    }
    &.selected {
      opacity: 1;
      background: var(--dark-blue);
      color: white;
    }
  }

  .form-header {
    padding-top: 40px;
    text-align: center;

    &:first-of-type {
      padding: 30px auto;
    }
  }

  .form-footer {
  }
  .form-footer__privacy {
    font-size: 0.75em;
    letter-spacing: 1.5px;
    opacity: 0.75;
  }
  .submit-button {
    display: block;
    margin: 1rem auto 2rem;
    background-color: var(--green);
    color: white;
  }

  .full-width @media (max-width: 630px) {
  }

  .question__text {
    margin-bottom: 12px;
  }

  /**
 * MEDIA QUERIES
 */
`;

export default UploadStyles;
