import styled from "styled-components";

const FormStyles = styled.div`
  display: block;

  fieldset {
    padding: 0;
  }

  & .form-body__input-container {
    width: 47%;
    margin-top: 0.4rem;
    margin-bottom: 0.5rem;
    padding: 0.6rem 0;

    .form-body__input {
      padding-right: 1.2rem;
    }

    &:first-child:not(:last-child) {
      float: left;
      margin-right: 1.5%;
    }
    &:last-child:not(:first-child) {
      float: right;
      margin-left: 1.5%;
    }
  }

  label {
    font-size: 0.7em;
    font-weight: bold;
    margin-bottom: 5px;
    display: inline-block;
    margin-bottom: 5px;
  }

  input:not([type="radio"]) {
    // & input {
    font-size: 1.5rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #f5f8fa;
    color: #33475b;
    line-height: 1.375;
    border: 1px solid;
    border-color: #cbd6e2;
    border-radius: 0.1875rem;
    width: 100%;
    min-height: 2.5rem;
    transition: all 0.15s ease-out;
    padding: 0.6rem;

    // padding: 0.5625rem 0.625rem;

    // width: 100% !important;
    // font-size: 0.8rem;
    // min-height: 2rem;
  }

  // --------------------

  .full-width-input {
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
  }
`;

export default FormStyles;
